import { gql } from '@apollo/client'

export default gql`
  query Incentives {
    incentives {
      id
      campaignId
      customerId
      name
      price
      description
      isAvailable
      type
      picture
      skuCode
      isActive
      categoryId
      categories {
        id
        incentiveCategory {
          id
          name
        }
      }
      files {
        id
        s3Key
        url
        aggregateId
        fileType
        fileVariant
      }
    }
  }
`
