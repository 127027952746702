import {
  NavigateFunction,
  useLocation,
  useOutletContext,
} from 'react-router-dom'
import { PartialEndUser, EndUserCampaign } from '../../../../server/src/graphql'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { TFunction } from 'i18next'
type ECComponentType = {
  campaign: EndUserCampaign
  theme: string
  endUser: PartialEndUser
  t: TFunction<'translation', undefined>
  pathname: string
  id?: string
  navigate: NavigateFunction
  onSubmit(questionnaire: unknown): void
  error: string
  setError(error: string): void
}
export default function Preview<T extends ECComponentType>({
  Component,
}: {
  Component: React.JSXElementConstructor<T | ECComponentType>
}) {
  const { t } = useTranslation()
  const location = useLocation()
  const { pathname } = location
  const { campaign, theme, endUser, error, setError } =
    useOutletContext<ECComponentType>()

  return (
    <Component
      campaign={campaign}
      theme={theme}
      endUser={endUser}
      t={t}
      pathname={pathname}
      navigate={() => {}}
      onSubmit={() => {}}
      error={error}
      setError={setError}
    />
  )
}
