import { ArrowLeft } from 'lucide-react'
import { LayoutProps } from '@sourcelabbg/form/lib'
import { SubmitHandler } from 'react-hook-form'
import { useEffect, useMemo, useState } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { zodResolver } from '@hookform/resolvers/zod'

import { AlphaTypes } from '@/components/form/custom-types'
import { Button } from '@/components/button'
import { Incentive, SkuCodeIncentiveItem } from '@/gql'
import { MultiSelect } from '@/components/multi-select'
import AlphaForm from '@/components/form'
import CREATE_SKU_CODE_MUTATION from '@/graphql/sku-builder/create'
import GET_SKU_CODE_QUERY from '@/graphql/sku-builder/get-sku-code'
import GET_SKU_CODE_LIST_QUERY from '@/graphql/sku-builder/get-sku-code-list'
import INCENTIVES_QUERY from '../../graphql/incentive/queries/incentives'
import UPDATE_SKU_CODE_MUTATION from '@/graphql/sku-builder/update'
import {
  CreateSKUSchema,
  UpdateSkuCodeType,
  UpdateSKUSchema,
} from '@/schema/sku-builder.schema'

export default function SkuFormBuilder() {
  const { id } = useParams()
  const navigate = useNavigate()
  const { t } = useTranslation()

  const [selectedIncentiveIds, setSelectedIncentiveIds] = useState<string[]>([])
  const [values, setValues] = useState<Partial<UpdateSkuCodeType>>()

  const {
    data: incentives,
    loading: loadingIncentives,
    error,
  } = useQuery(INCENTIVES_QUERY, {})
  const incentivesData = useMemo(
    () =>
      incentives?.incentives.filter(
        (incentive: Incentive) => incentive.isActive,
      ),
    [incentives],
  )
  const incentivesOptions =
    incentivesData?.map((incentive: Incentive) => ({
      value: incentive.id,
      label: incentive.name,
      picture: incentive.picture,
    })) ?? []

  const { data: skuData } = useQuery(GET_SKU_CODE_QUERY, {
    variables: { id },
    skip: !id,
  })

  const [createSkuCodeMutation, { error: createSkuError }] = useMutation(
    CREATE_SKU_CODE_MUTATION,
    {
      refetchQueries: [{ query: GET_SKU_CODE_LIST_QUERY }],
      onCompleted: () => {
        navigate('/incentives/sku-list')
      },
      onError: (error) => {
        console.error('SKU creation error:', error)
      },
    },
  )

  const [updateSkuCodeMutation, { error: updateSkuError }] = useMutation(
    UPDATE_SKU_CODE_MUTATION,
    {
      refetchQueries: [{ query: GET_SKU_CODE_LIST_QUERY }],
      onCompleted: () => {
        navigate('/incentives/sku-list')
      },
      onError: (error) => {
        console.error('SKU update error:', error)
      },
    },
  )

  useEffect(() => {
    if (skuData?.skuCode) {
      const formValues: UpdateSkuCodeType = {
        id: skuData.skuCode.id,
        name: skuData.skuCode.name,
        incentiveItemIds: skuData.skuCode.incentiveItems.map(
          (item: SkuCodeIncentiveItem) => item.incentiveItem.id,
        ),
      }
      setValues(formValues)
      setSelectedIncentiveIds(formValues.incentiveItemIds || [])
    }
  }, [skuData])

  const onSubmit: SubmitHandler<UpdateSkuCodeType> = (
    formData: UpdateSkuCodeType,
  ) => {
    const submitData = {
      ...formData,
      incentiveItemIds: selectedIncentiveIds,
    }

    if (id) {
      return updateSkuCodeMutation({
        variables: {
          skuCodeInput: {
            ...submitData,
            id: id,
          },
        },
      })
    }
    return createSkuCodeMutation({
      variables: {
        skuCodeInput: submitData,
      },
    })
  }

  if (loadingIncentives) return <div>{t('loading')}</div>
  if (error) return <div>{t('error')}</div>

  const createIncentiveFields: AlphaTypes[] = [
    {
      render: () => (
        <>
          <div className="flex items-center mt-10 mb-14">
            <Link
              to="/incentives/sku-list"
              className="flex items-center hover:text-primary"
            >
              <ArrowLeft className="w-8 h-8" />
            </Link>
            <h1 className="font-bold text-3xl mx-6 mb-0">
              {id
                ? t('sku_builder.update_sku_code')
                : t('sku_builder.add_sku_code')}
            </h1>
          </div>
          {(createSkuError || updateSkuError) && (
            <div className="text-red-500 my-4">
              {(createSkuError || updateSkuError)?.message}
            </div>
          )}
        </>
      ),
    },
    {
      name: 'name',
      type: 'input',
      uiOptions: {
        label: t('incentives.sku_code'),
        inputType: 'text',
        testId: 'sku',
        disabled: !!id, // Disable name field when updating
        className: id ? 'bg-gray-100' : '', // Visual feedback for disabled state
      },
    },
    {
      render: (layoutProps: LayoutProps) => {
        return (
          <div className="flex flex-col gap-1 my-2">
            <label>{t('sku_builder.assign_incentive')}</label>
            <MultiSelect
              options={incentivesOptions}
              selected={selectedIncentiveIds}
              onChange={setSelectedIncentiveIds}
              className="bg-white w-80"
            />
            {layoutProps.errors['incentiveItemIds'] && (
              <span className="text-primary">
                {t('validation_messages.required')}
              </span>
            )}
          </div>
        )
      },
    },
    {
      render: () => {
        console.log('id', id)
        return (
          <div className="flex justify-end my-10">
            <div className="grid grid-cols-2 gap-4 my-20">
              <Button
                type="button"
                name="cancel"
                label={t('cancel')}
                size="small"
                onClick={() => {
                  setSelectedIncentiveIds([])
                  navigate('/incentives')
                }}
                data-testid="incentive-cancel"
              />
              <Button
                primary={true}
                type="submit"
                name="add-incentive"
                label={
                  !id
                    ? t('sku_builder.add_sku_code')
                    : t('sku_builder.update_sku_code')
                }
                size="small"
                data-testid="incentive-submit"
              />
            </div>
          </div>
        )
      },
    },
  ]
  return (
    <div className="p-8 space-y-2 flex flex-col max-w-2xl m-auto">
      <AlphaForm
        fields={createIncentiveFields}
        onSubmit={onSubmit}
        resolver={zodResolver(id ? UpdateSKUSchema : CreateSKUSchema)}
        values={{
          ...values,
          incentiveItemIds: selectedIncentiveIds, // Make sure form knows about selected incentives
        }}
      />
    </div>
  )
}
