import { gql } from '@apollo/client'

export default gql`
  mutation UpdateSkuCode($skuCodeInput: UpdateSkuCodeInput!) {
    updateSkuCode(skuCodeInput: $skuCodeInput) {
      id
      name
      incentiveItems {
        id
        incentiveItem {
          id
          name
          picture
        }
      }
    }
  }
`
