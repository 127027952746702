import * as React from 'react'

import cx from 'classnames'
import { Button } from '@/components/ui/button'
import { Popover, PopoverContent, PopoverTrigger } from './popover'
import { Badge } from './ui/badge'
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from './ui/command'
import { CaretUpDown, Check, X } from '@phosphor-icons/react'
import { SelectOption } from './form/custom-select'
import { useTranslation } from 'react-i18next'
import { displayS3File } from '@/utils/s3'

interface MultiSelectProps {
  options: SelectOption[]
  selected: string[]
  onChange: React.Dispatch<React.SetStateAction<string[]>>
  className?: string
}

export function MultiSelect({
  options,
  selected = [],
  onChange,
  className,
  ...props
}: MultiSelectProps) {
  const { t } = useTranslation()
  const [open, setOpen] = React.useState(false)

  const handleUnselect = (item: string) => {
    onChange(selected.filter((i) => i !== item))
  }

  return (
    <div className="flex flex-col gap-2">
      <Popover open={open} onOpenChange={setOpen} {...props}>
        <PopoverTrigger asChild className="bg-white">
          <Button
            variant="outline"
            role="combobox"
            aria-expanded={open}
            className="h-full w-full justify-between"
            onClick={() => setOpen(!open)}
          >
            <span className="text-sm text-gray-400 bg-white">
              {t('multiselect_placeholder')}
            </span>
            <CaretUpDown className="h-4 w-4 shrink-0 opacity-50 ml-2" />
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-full p-0 bg-white">
          <Command className={className}>
            <CommandInput placeholder="Search ..." />
            <CommandEmpty>No item found.</CommandEmpty>
            <CommandGroup className="max-h-64 overflow-auto">
              {options?.map((option) => (
                <CommandItem
                  key={option.value}
                  onSelect={() => {
                    onChange(
                      selected.includes(option.value)
                        ? selected.filter((item) => item !== option.value)
                        : [...selected, option.value],
                    )
                    setOpen(true)
                  }}
                >
                  <Check
                    className={cx(
                      'mr-2 h-4 w-4',
                      selected.includes(option.value)
                        ? 'opacity-100'
                        : 'opacity-0',
                    )}
                  />
                  {option.label}
                </CommandItem>
              ))}
            </CommandGroup>
          </Command>
        </PopoverContent>
      </Popover>

      {selected.length > 0 && (
        <div className="flex flex-wrap gap-1 mt-1">
          {selected.map((item) => {
            const selectedOption = options.find((o) => o.value === item)
            const imageUrl = selectedOption?.picture
              ? displayS3File(selectedOption.picture)
              : undefined

            return (
              <Badge
                variant="outline"
                key={item}
                className="flex items-center gap-2 p-1 border rounded-lg"
                onClick={() => handleUnselect(item)}
              >
                {imageUrl && (
                  <img
                    src={imageUrl}
                    alt={selectedOption?.label || ''}
                    className="w-10 rounded-lg object-cover"
                    onError={(e) => {
                      console.error('Image failed to load:', imageUrl)
                      e.currentTarget.style.display = 'none'
                    }}
                  />
                )}
                <span className="font-thin text-gray-2">
                  {selectedOption?.label}
                </span>
                <span
                  role="button"
                  className="rounded-full outline-none ring-offset-background focus:ring-2 focus:ring-ring focus:ring-offset-2"
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      handleUnselect(item)
                    }
                  }}
                  onMouseDown={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                  }}
                  onClick={() => handleUnselect(item)}
                >
                  <X className="h-3 w-3 text-muted-foreground hover:text-foreground" />
                </span>
              </Badge >
            )
          })}
        </div >
      )}
    </div >
  )
}