import { z } from 'zod'

export enum ECFlowScreensType {
  HOME = 'home',
  INCENTIVE = 'incentive',
  QUESTIONNAIRE = 'questionnaire',
  INVITE = 'invite',
  HAPPINESS_CHECK = 'happiness-check',
  SUCCESS = 'success',
  EXPIRED = 'expired',
}

export enum TypeOfUser {
  END_USER = 'end-user',
  REFERRAL_USER = 'referral-user',
}

export const emailSchema = z.object({
  header: z.string().min(1, 'required'),
  text: z.string().min(1, 'required'),
})

const translationSchema = z.object({
  header: z.string().optional(),
  text: z.string().optional(),
  termsAndConditions: z.string().optional(),
  termsAndConditionsEndUser: z.string().optional(),
  termsAndConditionsReferral: z.string().optional(),
  termsAndConditionsSummary: z.string().optional(),
})

const dynamicKeySchema = z.object({
  //TODO change nl to dynamic key based on all supported languages
  nl: translationSchema,
})

export const ecFlowTextsSchema = z
  .object(
    Object.values(ECFlowScreensType).reduce((obj, key) => {
      return {
        ...obj,
        [key]: dynamicKeySchema.optional(),
      }
    }, {}),
  )
  .optional()

export const CreateECFlowTextsSchema = z.object({
  id: z.string().optional(),
  ecFlowTexts: z
    .object({
      [TypeOfUser.END_USER]: ecFlowTextsSchema,
      [TypeOfUser.REFERRAL_USER]: ecFlowTextsSchema,
      email: z.object({
        nl: emailSchema.required(),
      }),
    })
    .optional(),
})
export type ECFlowTextsFormType = z.infer<typeof CreateECFlowTextsSchema>
export type ECFlowTextsType = z.infer<typeof ecFlowTextsSchema>
export type EcFlowTexts = Pick<ECFlowTextsFormType, 'ecFlowTexts'>
