import { z } from 'zod'

export const CreateSKUSchema = z.object({
  name: z
    .string()
    .min(1, 'required')
    .min(4, 'SKU code must be at least 4 characters')
    .max(50, 'SKU code must be less than 50 characters'),
  incentiveItemIds: z
    .array(z.string().min(1, 'required'))
    .nonempty('At least one incentive must be selected')
    .describe('Note: Each incentive can only be assigned to one SKU'),
})

export const UpdateSKUSchema = z.object({
  id: z.string().min(1, 'required'),
  name: z.string(), // Name is required but not validated on update
  incentiveItemIds: z
    .array(z.string().min(1, 'required'))
    .nonempty('At least one incentive must be selected'),
})

export type CreateSkuCodeType = z.infer<typeof CreateSKUSchema>
export type UpdateSkuCodeType = z.infer<typeof UpdateSKUSchema>
