import { gql } from '@apollo/client'

export default gql`
  mutation CreateSkuCode($skuCodeInput: CreateSkuCodeInput!) {
    createSkuCode(skuCodeInput: $skuCodeInput) {
      id
      name
      incentiveItems {
        id
        incentiveItem {
          id
          name
          picture
        }
      }
    }
  }
`
