import { gql } from '@apollo/client'

export default gql`
  query GetSkuCode($id: ID!) {
    skuCode(id: $id) {
      id
      name
      incentiveItems {
        id
        incentiveItem {
          id
          name
          picture
        }
      }
    }
  }
`
