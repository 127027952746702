import { useParams, useNavigate, useOutletContext } from 'react-router-dom'
import { useSetRecoilState } from 'recoil'
import { activeStepState } from '../../store/atoms/active-step'
import { useEffect, useMemo } from 'react'
import CREATE_EC_FLOW_TEXTS from '../../graphql/campaign/mutations/create-ec-flow-texts'
import { useMutation } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import { AlphaTypes } from '../../components/form/custom-types'
import AlphaForm from '../../components/form'
import { GetCampaignFragment } from '../../graphql/campaign/fragments/get-campaign'
import CampaignButtons from '../../components/campaign-buttons'
import { SubmitHandler } from 'react-hook-form'
import { ECFlowTextsFormType, emailSchema } from '@/schema/ec-flow-texts.schema'
import { zodResolver } from '@hookform/resolvers/zod'

export default function Emails() {
  const { id } = useParams()
  const { t } = useTranslation()
  const navigate = useNavigate()

  const { campaign: campaignData } = useOutletContext<{
    campaign: GetCampaignFragment
  }>()

  const setActiveStep = useSetRecoilState(activeStepState)
  const [createECFlowTexts] = useMutation(CREATE_EC_FLOW_TEXTS, {})

  const campaign: ECFlowTextsFormType = useMemo(() => {
    return campaignData?.ecFlowTexts || {}
  }, [campaignData])

  const values = useMemo(
    () => (campaign?.ecFlowTexts as any)?.email.nl,
    [campaign.ecFlowTexts],
  )

  const emailsForm: AlphaTypes[] = useMemo(
    () => [
      {
        render: () => (
          <div className="mb-5">
            <h1 className="text-black-1 font-bold mb-0">
              {t('emails.header')}
            </h1>
            <p className="text-gray-2 text-sm font-normal">
              {t('emails.text')}
            </p>
          </div>
        ),
      },

      {
        name: 'header',
        type: 'input',
        uiOptions: {
          label: t('ec_flow_texts.subject'),
          labelClassName: 'text-sm text-grey-1',
          inputType: 'text',
          testId: 'header',
        },
      },
      {
        name: 'text',
        type: 'custom',
        customControlType: 'quillEditor',
        uiOptions: {
          label: t('ec_flow_texts.email_text'),
          labelClassName: 'text-sm text-grey-1',
          testId: 'text',
          className: 'h-40',
        },
        maxChars: 500,
      },
      {
        render: () => (
          <CampaignButtons submitLabel={t('next')} saveDraftLabel={t('save')} />
        ),
      },
    ],
    [id],
  )

  useEffect(() => setActiveStep(8), [setActiveStep])

  const onSubmit: SubmitHandler<ECFlowTextsFormType> = (
    data: Record<string, unknown>,
    e,
  ) => {
    const buttonName = (
      (e?.nativeEvent as SubmitEvent) || undefined
    )?.submitter?.getAttribute('name')

    createECFlowTexts({
      variables: {
        ecFlowTexts: {
          ecFlowTexts: {
            ...campaign.ecFlowTexts,
            email: { nl: { ...data } },
          },
          id: campaign?.id ?? id,
        },
      },
      onCompleted() {
        if (buttonName === 'next') {
          navigate(`/campaigns/happiness-check/${campaignData.id}`)
        }
      },
    })
  }

  return (
    <AlphaForm
      fields={emailsForm}
      values={values}
      onSubmit={onSubmit}
      resolver={zodResolver(emailSchema)}
      className="w-full max-w-5xl"
    />
  )
}
