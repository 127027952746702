import {
  Tabs as TabsRoot,
  TabsContent,
  TabsList as TabsListPrimitive,
  TabsTrigger as TabsTriggerPrimitive,
} from '@/components/ui/tabs'
import { cn } from '@/lib/utils'

export interface TabItem {
  value: string
  label: string
  content?: React.ReactNode
}

interface TabsProps {
  tabs: TabItem[]
  defaultValue?: string
  className?: string
  onChange?: (value: string) => void
}

const TabsList = ({
  className,
  ...props
}: React.ComponentPropsWithoutRef<typeof TabsListPrimitive>) => (
  <TabsListPrimitive
    className={cn(
      'inline-flex h-14 items-center justify-center bg-transparent',
      className,
    )}
    {...props}
  />
)

const TabsTrigger = ({
  className,
  ...props
}: React.ComponentPropsWithoutRef<typeof TabsTriggerPrimitive>) => (
  <TabsTriggerPrimitive
    className={cn(
      'inline-flex items-center justify-center whitespace-nowrap rounded-md px-6 py-1.5 text-lg ring-offset-background transition-all focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50',
      'data-[state=active]:bg-ui1 data-[state=active]:rounded-md',
      className,
    )}
    {...props}
  />
)

export function Tabs({ tabs, defaultValue, className, onChange }: TabsProps) {
  return (
    <TabsRoot
      defaultValue={defaultValue || tabs[0]?.value}
      className={className}
      onValueChange={onChange}
    >
      <TabsList>
        {tabs.map((tab) => (
          <TabsTrigger key={tab.value} value={tab.value}>
            {tab.label}
          </TabsTrigger>
        ))}
      </TabsList>
      {tabs.map((tab) => (
        <TabsContent key={tab.value} value={tab.value}>
          {tab.content}
        </TabsContent>
      ))}
    </TabsRoot>
  )
}
