import { Plus } from '@phosphor-icons/react'
import { useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import cx from 'classnames'

import { Button } from '@/components/button'
import { displayS3File } from '@/utils/s3'
import GET_SKU_CODE_LIST_QUERY from '@/graphql/sku-builder/get-sku-code-list'
import Tooltip from '@/components/tooltip'
import { SkuCode, SkuCodeIncentiveItem } from '@/gql'

export default function SkuList() {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [searchTerm, setSearchTerm] = useState('')

  const {
    data: skuData,
    error: skuError,
    loading,
  } = useQuery(GET_SKU_CODE_LIST_QUERY, {
    onError: (error) => {
      console.error('SKU Query Error:', error)
    },
  })

  const skuCodesList: SkuCode[] = useMemo(() => {
    if (!skuData?.skuCodeList) return []
    return skuData.skuCodeList.filter((sku: SkuCode) => {
      const searchLower = searchTerm.toLowerCase()
      const matchesSku = sku.name.toLowerCase().includes(searchLower)
      const matchesIncentive = sku.incentiveItems.some((item) =>
        item.incentiveItem.name.toLowerCase().includes(searchLower),
      )
      return matchesSku || matchesIncentive
    })
  }, [skuData, searchTerm])

  if (loading) return <div>{t('loading')}</div>
  if (skuError) return <div>{t('error')}</div>

  const rowContentClassName = 'px-5 py-4 text-sm text-gray-1 items-center '
  const tableHeadersClassName =
    'whitespace-nowrap px-5 py-4 text-left text-sm font-normal text-gray-1'

  return (
    <>
      <div className="mb-4 flex flex-col gap-2 md:flex-row md:justify-between">
        <div className="flex flex-col w-full md:flex-row justify-between gap-2">
          <input
            type="text"
            placeholder={t('sku_builder.search_placeholder')}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="border border-gray-300 rounded-md p-2 h-10 w-1/4"
          />
          <Button
            label={t('sku_builder.add_sku')}
            onClick={() => navigate('/incentives/sku-list/create')}
            IconLeft={Plus}
            className="border border-primary text-primary hover:bg-primary hover:text-white focus:bg-primary focus:text-white focus-visible:outline focus:ring-primary"
            size="small"
          />
        </div>
      </div>
      <div
        className={cx(
          !skuCodesList?.length ? undefined : 'overflow-scroll',
          'p-8 sm:rounded-lg bg-white',
        )}
      >
        {skuCodesList.length ? (
          <div className="border sm:rounded-lg ">
            <table className="w-full table-auto">
              <thead className="">
                <tr className="border-b h-14">
                  <th scope="col" className={tableHeadersClassName}>
                    {t('sku_builder.assigned')}
                  </th>
                  <th scope="col" className={tableHeadersClassName}>
                    {t('sku_builder.sku_code')}
                  </th>
                  <th scope="col" className={tableHeadersClassName}>
                    {t('sku_builder.incentives')}
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {skuCodesList.map((sku: SkuCode) => (
                  <tr
                    onClick={() => navigate(`update/${sku.id}`)}
                    key={sku.id}
                    className="border-b h-14 cursor-pointer"
                  >
                    <td className={cx(rowContentClassName, 'w-24')}>
                      <Tooltip
                        child={
                          <div className="flex items-center gap-x-2">
                            <div className="bg-primary w-2 h-2 rounded-full"></div>
                            {sku.incentiveItems.length}
                          </div>
                        }
                        text={t('sku_builder.featured_tooltip', {
                          numberOfIncentives: sku.incentiveItems.length,
                        })}
                      />
                    </td>
                    <td className={cx(rowContentClassName, 'w-80')}>
                      {sku.name}
                    </td>
                    <td
                      className={cx(
                        rowContentClassName,
                        'grid grid-cols-5 gap-4',
                      )}
                    >
                      {sku.incentiveItems.map(
                        (incentive: SkuCodeIncentiveItem) => {
                          return (
                            <div
                              key={incentive.id}
                              className="flex items-center w-full rounded-lg p-1 border"
                            >
                              <div className="w-14 h-10 flex">
                                <img
                                  className="rounded-md max-w-10 max-h-10"
                                  src={displayS3File(
                                    incentive.incentiveItem.picture ?? '',
                                  )}
                                  alt={incentive.incentiveItem.name}
                                />
                              </div>

                              <div className="min-w-0 flex-1 truncate pl-1">
                                {incentive.incentiveItem.name}
                              </div>
                            </div>
                          )
                        },
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <div className="w-full text-center h-96 mt-28">
            <p>{t('sku_builder.no_sku')}</p>
            <Button
              label=""
              onClick={() => navigate('/incentives/sku-list/create')}
            >
              {t('sku_builder.please_add_sku')}
            </Button>
          </div>
        )}
      </div>
    </>
  )
}
