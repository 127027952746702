import { gql } from '@apollo/client'

export default gql`
  query IncentivesByCategory(
    $incentiveCategoryId: ID
    $customerId: ID
    $campaignId: ID
  ) {
    incentivesByCategory(
      incentiveCategoryId: $incentiveCategoryId
      customerId: $customerId
      campaignId: $campaignId
    ) {
      id
      campaignId
      customerId
      integrationId
      name
      price
      description
      isAvailable
      type
      picture
      skuCode
      categoryId
      isActive
      categories {
        id
        incentiveCategory {
          id
          name
        }
      }
      isAttachedToLotteryCampaign
      incentiveAttachedToCampaign
      files {
        id
        s3Key
        url
        aggregateId
        fileType
        fileVariant
      }
    }
  }
`
