import { useTranslation } from 'react-i18next'
import { useNavigate, useLocation } from 'react-router-dom'
import { useState } from 'react'
import { Tabs, TabItem } from '@/components/tabs'
import SkuList from '../sku-builder/sku-list'
import IncentivesStoreList from './incentives-store-list'

export default function IncentivesStore() {
  const navigate = useNavigate()
  const location = useLocation()
  const { t } = useTranslation()
  const [activeTab, setActiveTab] = useState(() =>
    location.pathname.includes('sku-list') ? 'sku' : 'incentive',
  )

  const handleTabChange = (value: string) => {
    setActiveTab(value)
    if (value === 'sku') {
      // Check if we're already on the sku-list path to prevent duplication
      if (!location.pathname.includes('sku-list')) {
        navigate('sku-list', { replace: true })
      }
    } else {
      // Navigate to the root incentives path
      navigate('/incentives', { replace: true })
    }
  }

  const tabs: TabItem[] = [
    {
      value: 'incentive',
      label: t('sku_builder.incentives'),
    },
    {
      value: 'sku',
      label: t('sku_builder.sku_list'),
    },
  ]

  return (
    <div className="bg-gray-8 p-8 h-full">
      <Tabs
        tabs={tabs}
        defaultValue={activeTab}
        className="w-full mb-8"
        onChange={handleTabChange}
      />
      {activeTab === 'incentive' ? <IncentivesStoreList /> : <SkuList />}
    </div>
  )
}
