import { Button } from '../../../client/src/components/button'
import cx from 'classnames'
import { TFunction } from 'i18next'
import { NavigateFunction } from 'react-router-dom'

export const BackButton = ({
  className,
  navigate,
  t,
}: {
  navigate: NavigateFunction
  className?: string
  t: TFunction<'translation', undefined>
}) => {
  return (
    <Button
      onClick={() => navigate(-1)}
      name="back"
      label={t('ec.back')}
      data-testid="back-button"
      className={cx(
        'theme-theme1:bg-transparent',
        'border',
        'rounded-md',
        'm-auto',
        'mobile:w-80 fold:w-60',
        'text-black-1',
        'border-black-1',
        'hover:text-theme-button-hover',
        'hover:border-theme-button-border-hover',
        className,
      )}
    />
  )
}

export const NextButton = ({
  type = 'button',
  onClick,
  className,
  label,
  t,
  disabled,
}: {
  type?: 'button' | 'submit'
  onClick?: () => void
  className?: string
  label?: string
  t: TFunction<'translation', undefined>
  disabled?: boolean
}) => {
  return (
    <Button
      disabled={disabled}
      type={type}
      onClick={onClick}
      name="save"
      label={label || t('ec.next')}
      data-testid="save-draft"
      className={cx(
        'bg-theme-button',
        'hover:bg-theme-button-hover',
        'hover:theme-button-border-hover',
        'text-white',
        'hover:text-white',
        'active:text-white !important',
        'focus:text-white',
        'border',
        'border-black-1 !bg-black-1',
        'rounded-md',
        'm-auto',
        'mobile:w-80 fold:w-60',
        'active:bg-gray-2',
        disabled ? 'opacity-50 cursor-auto ' : '',
        className,
      )}
    />
  )
}
