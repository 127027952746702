import { gql } from '@apollo/client'

export default gql`
  query SkuCodeList {
    skuCodeList {
      id
      name
      incentiveItems {
        id
        incentiveItem {
          id
          name
          picture
          categoryId
        }
      }
    }
  }
`
